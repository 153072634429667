import { useIonAlert, IonButton, IonIcon, IonPopover, IonContent, IonItem, IonLabel } from '@ionic/react';
import { chevronDown, chevronForward } from 'ionicons/icons';
import { useRef } from 'react';
import { QmLocation, updateMultipleQmServiceLocation } from '../../../features/integrations/qm/integrationApi';
import { QmLocationRow } from '../../../features/integrations/qm/locations/IntegrationsLocationsGrid';
import IntegrationsLocationsGridDefaultOwnerPicker from '../../../features/integrations/qm/locations/IntegrationsLocationsGridDefaultOwnerPicker';
import { Person } from '../../../features/people/peopleApi';
import { Row } from '@grapecity/wijmo.grid';

interface IntegrationsLocationsGridActionButtonProps {
  people: Person[];
  defaultListOwner: string | undefined;
  selectedLocations: Row[];
  setSelectedLocations: React.Dispatch<React.SetStateAction<Row[]>>;
  setData: React.Dispatch<React.SetStateAction<QmLocation[] | undefined>>;
}

const IntegrationsLocationsGridActionButton: React.FC<IntegrationsLocationsGridActionButtonProps> = ({
  people,
  defaultListOwner,
  selectedLocations,
  setSelectedLocations,
  setData,
}) => {
  const [presentAlert] = useIonAlert();

  const popoverRef = useRef<HTMLIonPopoverElement>(null);

  const onApplyDefaultListOwnerHandler = (personId: string) => {
    setData((prevData) => {
      return prevData?.map((location) => {
        if (
          selectedLocations.some(
            (selectedItem) => (selectedItem.dataItem as QmLocationRow).serviceLocationId === location.serviceLocationId
          )
        ) {
          return {
            ...location,
            defaultListOwner: personId,
          };
        }
        return location;
      });
    });

    updateMultipleQmServiceLocation(
      selectedLocations.map((row) => {
        const location = row.dataItem as QmLocationRow;
        return {
          serviceLocationId: location.serviceLocationId,
          autoGenerateLists: location.autoGenerateLists,
          defaultListOwner: personId,
        };
      })
    );

    setSelectedLocations([]);
  };

  const onAutoListsToggleHandler = (newValue: boolean) => {
    setData((prevData) => {
      return prevData?.map((location) => {
        if (
          selectedLocations.some(
            (selectedItem) => (selectedItem.dataItem as QmLocationRow).serviceLocationId === location.serviceLocationId
          )
        ) {
          return {
            ...location,
            autoGenerateLists: newValue,
          };
        }
        return location;
      });
    });

    updateMultipleQmServiceLocation(
      selectedLocations.map((row) => {
        const location = row.dataItem as QmLocationRow;
        return {
          serviceLocationId: location.serviceLocationId,
          autoGenerateLists: newValue,
          defaultListOwner: location.defaultListOwner,
        };
      })
    );

    setSelectedLocations([]);

    popoverRef.current?.dismiss();
  };

  return (
    <>
      <IonButton
        style={{ '--border-radius': '8px', fontSize: '16px' }}
        id="integrationLocationsGrid-action-menu-popover"
        disabled={selectedLocations.length === 0}
      >
        Action
        <IonIcon slot="end" icon={chevronDown} size="small" />
      </IonButton>

      <IonPopover
        ref={popoverRef}
        trigger="integrationLocationsGrid-action-menu-popover"
        triggerAction="click"
        size="auto"
        side="bottom"
        alignment="end"
        showBackdrop={false}
      >
        <IonContent>
          <IonItem button lines="none" id="locationsGridDefaultOwnerPicker-trigger">
            <IonLabel>Change Default List Owner</IonLabel>
            <IonIcon slot="end" icon={chevronForward} size="small" />
            <IntegrationsLocationsGridDefaultOwnerPicker
              trigger="locationsGridDefaultOwnerPicker-trigger"
              people={people}
              defaultListOwner={defaultListOwner}
              onApplyHandler={onApplyDefaultListOwnerHandler}
            />
          </IonItem>
          <IonItem
            button
            lines="none"
            onClick={() =>
              presentAlert({
                header: 'Turn On Auto List Generation',
                message: 'Are you sure you want to turn On Auto List Generation for the selected locations?',
                buttons: [
                  {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'alert-button-cancel',
                  },
                  {
                    text: 'Turn On',
                    handler: () => onAutoListsToggleHandler(true),
                  },
                ],
              })
            }
          >
            <IonLabel>Turn "On" Auto List Generation</IonLabel>
          </IonItem>
          <IonItem
            button
            lines="none"
            onClick={() =>
              presentAlert({
                header: 'Turn off Auto List Generation',
                message: 'Are you sure you want to turn off Auto List Generation for the selected locations?',
                buttons: [
                  {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'alert-button-cancel',
                  },
                  {
                    text: 'Turn Off',
                    handler: () => onAutoListsToggleHandler(false),
                  },
                ],
              })
            }
          >
            <IonLabel>Turn "Off" Auto List Generation</IonLabel>
          </IonItem>
        </IonContent>
      </IonPopover>
    </>
  );
};

export default IntegrationsLocationsGridActionButton;
